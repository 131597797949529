// React dependencies
import WOW from "wowjs";
import React, { useEffect } from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
// Components
import Header from "components/header/HeaderThree";
import Footer from "container/footer/FooterOne";
import Error from "./pages/others/Error";
import Home from "./pages/homes/HomeCreativeAgency";
import Contact from "pages/contact/Contact";
import Investors from "pages/investors/Investors";
import Projects from "pages/projects/Projects";
import Partnerships from "pages/partnerships/Partnerships";
import Indigenous from "pages/indigenous/Indigenous";
import Sector from "pages/sectors/Sector";
import AboutUs from "pages/about-us/AboutUs";
import history from "./history";
import ScrollHandler from "components/ScrollHandler";
const App = () => {

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <Router history={history}>
      <ScrollHandler />
      <Header />
      <Scrollbar
        damping={0.1}
        thumbMinSize={10}
        renderByPixels={true}
        alwaysShowTracks={true}
        continuousScrolling={true}
      >
        {/* <ScrollToTop> */}
        <Switch>
          {/* Mining Section Routing */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/helios-x"}`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/dajin"}`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/enviroleach"}`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/envirogold"}`}
            component={Contact}
          />
          {/* Other links */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/investors"}`}
            component={Investors}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/projects"}`}
            component={Projects}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/partnerships"}`}
            component={Partnerships}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/indigenous"}`}
            component={Indigenous}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/sector/:name"}`}
            component={Sector}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/about-us"}`}
            component={AboutUs}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          />
          <Route exact component={Error} />
        </Switch>
        {/* </ScrollToTop> */}
      </Scrollbar>
      <Footer className="fixed-footer dark" />
    </Router>
  );
};

export default App;
