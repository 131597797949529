import React, { useState } from "react";
import { Link } from "react-router-dom";
import navbarData from "data/navbar/navbar";

const NavbarItem = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const handleMouseEnter = (e) => {
    // const mega = document.querySelector(".mega__width--fullscreen");
    // mega.style.display = "block";

    if (e.target.id === "dropdown-true") {
      let elem = document.getElementById("onHoverClassAdd");
      elem.classList.add("hover-bg-change");
    }
    setShowMenu(true);
  };
  const handleMouseLeave = (e) => {
    let elem = document.getElementById("onHoverClassAdd");
    elem.classList.remove("hover-bg-change");
    setShowMenu(false);
    // const mega = document.querySelector(".mega__width--fullscreen");
    // mega.style.display = "none";
  };
  const closeMega = () => {
    setShowMenu(false);
  };
  return navbarData.map((navbar) => (
    <li
      key={navbar.id}
      className={`label-1 ${
        navbar.megaMenu || navbar.subMenu ? "with--drop" : ""
      } ${navbar.megaMenu ? "slide--megamenu" : ""} ${
        navbar.subMenu ? "slide-dropdown" : ""
      }`}
      id={navbar.megaMenu ? "dropdown-true" : ""}
      onMouseEnter={(e) => handleMouseEnter(e)}
      onMouseLeave={(e) => handleMouseLeave(e)}
    >
      <Link
        id={navbar.megaMenu ? "dropdown-true" : ""}
        to={`${process.env.PUBLIC_URL + navbar.link}`}
      >
        <span id={navbar.megaMenu ? "dropdown-true" : ""}>{navbar.title}</span>
      </Link>
      {navbar.subMenu ? (
        <ul className="dropdown__menu">
          {navbar.subMenu.lists.map((subItem) => (
            <li key={subItem.id}>
              <Link to={`${process.env.PUBLIC_URL + subItem.link}`}>
                <span>{subItem.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
      {navbar.megaMenu && showMenu ? (
        <div className="mega__width--fullscreen">
          <div className="container">
            <div className="grid-row row">
              {navbar.megaMenu.map((megaItem) => (
                <div key={megaItem.id} className="grid-column col">
                  <ul className="mega__list">
                    {navbar.title !== "Sectors" && (
                      <li className="mega--title">{megaItem.megaTitle}</li>
                    )}

                    {Object.values(megaItem.lists).map((list) => (
                      <li key={list.id}>
                        <div className="sector-block">
                          {/* <span className="a-icon -dropdown" data-component="a02-icon" data-icon="design-construction"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96"><path d="M33.17 55.67l-8.68 4.49v4.62L48 76.93l23.51-12.15v-4.62L63 55.77l4.77-2.36 8 4.15v9.77L48 81.75 20.21 67.4v-9.78l8.4-4.36zM48 52.82L20.21 38.46v-9.77L48 14.25l27.79 14.37v9.77l-1.16.67zm-23.51-17L48 48l23.51-12.16v-4.61L48 19.07 24.49 31.23z"></path><path d="M48 67.29L20.21 52.93v-9.78L48 28.72l27.79 14.37v9.77l-1.16.67zm-23.51-17L48 62.47l23.51-12.16V45.7L48 33.54 24.49 45.7z"></path><path d="M48 67.29L20.21 52.93v-9.78L48 28.72l27.79 14.37v9.77l-1.16.67zm-23.51-17L48 62.47l23.51-12.16V45.7L48 33.54 24.49 45.7z"></path></svg></span> */}
                          <span href="" className="sector-icon">
                            <img
                              className="svg-header-icon"
                              src={list.icon}
                              alt={list.title}
                            />
                          </span>
                          <Link
                            onClick={closeMega}
                            className="sector-link"
                            to={list.link}
                          >
                            <span>{list.title}</span>
                          </Link>
                          <div className="sector-img">
                            <img src={list.bgimg} alt="" />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </li>
  ));
};

export default NavbarItem;
