import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import helios from "data/helios.json";

// import flicker from "components/animations/flicker.js";
import LayoutDefault from "layouts/LayoutDefault";
import HeroBanner from "container/hero-banner/HeroCreativeAgency";
import About from "container/about-us/AboutCreativeAgency";
import Services from "container/service/ServiceFive";
import {
  homePageData,
  ourValues,
  homepageBannerData,
} from "data/home-page/homepage";
import Triangle from "components/triangle";
import Sectors from "components/sectors/Sectors";
// import Sectors from "pages/sectors/Sector";
// import Portfolios from "container/portfolio/homes/PortfolioNine";
import services from "data/service/ServiceArchitecture.json";
import './index.css';

const HomeCreativeAgency = () => {
  const whoWeAre = homePageData.whoWeAre;
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{`Home | ${helios.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-2 template-font-3"
        revealFooter={true}
      >
        <HeroBanner
          title={homepageBannerData.title}
          description={homepageBannerData.description}
          videoLink={homepageBannerData.videoLink}
          className="video-homepage"
        />
        <About
          title={whoWeAre.title}
          miniTitleBasic={whoWeAre.miniTitleBasic}
          description={whoWeAre.description}
          button={whoWeAre.button}
          link={whoWeAre.link}
        />
        <Sectors />
        <About
          title={ourValues.title}
          miniTitleBasic={ourValues.miniTitleBasic}
          miniTitleStyle={ourValues.miniTitleStyle}
          description={ourValues.description}
          button={ourValues.button}
          link={ourValues.link}
        />
        <Triangle />
        <Services services={services} />
      </LayoutDefault>
    </>
  );
};

export default HomeCreativeAgency;
