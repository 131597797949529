import React, { useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

const ScrollHandler = () => {

    const { pathname, hash } = useLocation()

    const handleScroll = () => {
        const element = document.getElementById(hash.replace("#", ""));

        setTimeout(()=> {
            window.scrollTo({
                behavior: element ? "smooth" : "auto",
                top: element ? element.offsetTop - 80 : 0
            });
        }, 100);
    }

    useEffect(()=>{
        handleScroll();
    }, [pathname, hash])

    return null
};

export default withRouter(ScrollHandler)