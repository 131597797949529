import svg1 from "../../assets/svg/icons/svg-1.svg";
import svg2 from "../../assets/svg/icons/svg-2.svg";
import svg3 from "../../assets/svg/icons/svg-3.svg";
import svg4 from "../../assets/svg/icons/svg-4.svg";
import vid from "../../assets/videos/projects.m4v";
import rngPresentation from "../../data/footer/rng-presentation.pdf";
import bilcnPresentation from "../../data/footer/bilcn-presentation.pdf";

import sap from "../../assets/img/client/sapling.png";
export const projectsBannerData = {
  title: "Projects",
  description: "Cotnact us come on dude",
  videoLink: vid,
};

export const projects = [
  {
    id: "#big-island-lake-cree",
    headingBlack1: "Big Island Lake Cree",
    headingBlack2: "Nation",
    headingGold1: "Fiber Optic Installation",
    //headingGold2: "Installation",
    para1:
      "Digital infrastructure is the next nation building endeavor, much like the first roads, and railroads.  That’s why Helios is committed to ensuring Big Island Lake Cree Nation is afforded the education, healthcare and ecommerce opportunities available to the rest of Canada through state-of-the-art fiber infrastructure.",
    para2:
      "Currently the Nation has internet bandwidth between 5 and 10 megabytes per second.  The new system will increase that to more than 1 gigabyte per second.  Covid underscored how vulnerable the Nation and its youth are as their online education was severely constrained.  We believe every child does matter and that a level playing field is essential.",
    link: bilcnPresentation,
    cardsData: [
      {
        icon: sap,
        text: "Approximately 55 kilometers of Fiber will be installed to connect the 135 homes and public buildings on Reserve.",
      },
      {
        icon: sap,
        text: "A comprehensive site survey has been conducted to map out the entire project and identify other utility infrastructure.",
      },
      {
        icon: sap,
        text: "The entire installation cost is estimated at $10 million. It will commence in 2022 and the entire Nation will be connected by late 2023/early 2024.",
      },
    ],
  },
  {
    id: "#renewable-natural-gas",
    headingBlack1: "Wood Waste to",
    headingBlack2: "",
    headingGold1: "Renewable Natural Gas (RNG) Project",
    headingGold2: "",
    para1:
      `Renewable Natural Gas (RNG) will play an important role in the fight against climate change as it reduces Greenhouse Gas (GHG) emissions by up to 85% compared to conventional fossil fuels.`,
    para2:
      "eCarbon capitalizes on this efficiency by harnessing a proven technology process to convert wood waste into RNG, power and Biochar.  eCarbon will salvage wood waste from mills, bug infestations and forest fires and convert it to biomethane.",
    para3: 
      "Chlorophyll in plants is the most efficient process for extracting C02  out of the atmosphere as 1 tree absorbs about 1 tonne of C02  over 40 years.",
    link: rngPresentation,
    cardsData: [
      {
        icon: sap,
        heading: "The Business Opportunity",
        text: `In 2015, the Government of Canada commits to Net Zero by 2050.
        British Columbia Government mandates that 15% of all Natural Gas must come from Renewables by 2030.
        The British Columbia Utilities Commission has given utilities approval to contract 20 year RNG off-take agreements, which guarantees a minimum, mid-teens Return On Investment.
        `,
      },
      {
        icon: sap,
        heading: "Why British Columbia?",
        text: `BC Produces lots of Methane,
        15% RNG consumption could translate to about 25 million tonnes of CO2 reduction per year.
        BC’s 139 mills only convert 45% into lumber, and the remaining 65% could be used as feedstock, along with salvaged bug kill and fire ravaged forests. This would also help prevent future forest fires.`
      },
      {
        icon: sap,
        heading: "The Technology",
        text: `The Pyrolysis/ Gasification process is a proven technology that creates a self-sustaining power supply as it creates Renewable Natural Gas - with no CO2 emissions.`,
      },
      {
        icon: sap,
        heading: "The Competition",
        text: `The BC Government’s RNG target is 26,700,000 GJ/year, and as of 2020, it has only achieved 0.3% of its goal. As of 2020, 8 projects using anaerobic digestion produced an average of 45,000 GJ/year for a combined total of 360,000 GJ/year. To meet government targets would require 600 anaerobic digestion facilities. Our Pyrolysis Gasification Facility will produce about 335,000 GJ/year and would only require 75 facilities to meet the government’s target.`,
      },
      {
        icon: sap,
        heading: "Next Steps",
        text: `Engage HATCH Engineering to conduct the detailed engineering design to ensure mass and energy material balances will fulfill commercial RNG contracts. Identify a location near a logistical hub, feedstock supply and qualified labour pool. Significant consideration is being given to locate the facility on Nation lands to ensure maximum indigenous benefits.  eCarbon will also forge partnerships with Indigenous Nations, municipalities and lumber companies.`,
      },
    ],
  },
  // {
  //   id: "#heliosx",
  //   headingGold1: "",
  //   para1: "HeliosX is an integrated lithium exploration company incorporating the latest in Direct Lithium Extraction (“DLE”) technologies to unlock 560,000 acres of potential commercial opportunities in Jujuy (Argentina), Nevada (USA) and Alberta (Canada). The Company has commercial agreements to utilize the latest in environmental technologies to remediate old mine tailings while extracting commercial precious metals, and plans to own and operate the first iodine-based gold concentrate extraction facility in North America.",
  // }
];
