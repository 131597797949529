import React from "react";
import Fade from "react-reveal/Fade";
function CardsSections({ cardsData }) {
  return (
    <div className="cards--section">
      <div className="card--container">
        {cardsData.map((x) => {
          return (
            <>
              <div className="cards--box-container">
                <Fade bottom>
                  <img
                    style={{ maxWidth: "5em" }}
                    className="cards--box-container-icon"
                    src={x.icon}
                    alt={x.heading}
                  />
                </Fade>

                <Fade bottom>
                  <h4 className="card--box-container-heading">{x.heading}</h4>
                </Fade>
                <Fade bottom>
                  <p className="card--box-container-text">{x.text}</p>
                </Fade>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default CardsSections;
