import React from "react";

const PageContainer = ({ children, className, revealFooter }) => {
  // const [footerHeight, setFooterHeight] = useState(0);
  // useEffect(() => {
  //   if (revealFooter) {
  //     const pageFooter = document.querySelector("footer");
  //     const footerHeight = pageFooter.offsetHeight;
  //     setFooterHeight(footerHeight);
  //   }
  // }, [revealFooter]);

  return (
    <div
      className={`wrapper ${revealFooter ? "all-wrapper" : ""} ${className}`}
      id="wrapper"
    >
      {children}
    </div>
  );
};

export default PageContainer;
