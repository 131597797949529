import React from "react";

const PortfolioItemOne = ({ className, thumb, title }) => {
  return (
    <div className={`portfolio portfolio_style--1 ${className}`}>
      <div className="thumb">
        <img src={thumb} alt={title} />
      </div>
    </div>
  );
};

export default PortfolioItemOne;
