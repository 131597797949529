import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import sectorsData from "data/sectors-comp/sectors";

const ServiceItemThree = ({ data }) => {
  let url = window.location.pathname; 
  let currentPageData = sectorsData[url];
  let color1 =
    currentPageData && currentPageData.color1
      ? currentPageData.color1
      : "#2C818D";
  let color2 =
    currentPageData && currentPageData.color2
      ? currentPageData.color2
      : "#205d66";

  const handleMouseEnter = (num) => {
    // e.target.style.backgroundColor = color2;
    // console.log(e.target.style, color2, color1);
    let elem = document.getElementById("hoverEvent" + num);
    elem.style.backgroundColor = color2;
  };
  const handleMouseLeave = (num) => {
    // e.target.style.backgroundColor = color1;
    let elem = document.getElementById("hoverEvent" + num);
    elem.style.backgroundColor = color1;
  };
  return (
    <div
      className="hover-bg-img-4"
      id={"hoverEvent" + data.number}
      onMouseEnter={() => handleMouseEnter(data.number)}
      onMouseLeave={() => handleMouseLeave(data.number)}
      style={{
        backgroundColor: color1,
        backgroundImage: `linear-gradient(${color2}, ${color2})`,
      }}
    >
      <div className="first-item-title">
        <p className="h-theme-xs cream">{currentPageData && currentPageData.head ? currentPageData.head : 'Our Goals'}</p>
      </div>
      <div className="architecture-inner">
        <div className="icon mb--25"></div>
        <Fade top>
          <div className="content">
            <h4 className="heading heading-h4 text-white">
              0{data.number + 1}
            </h4>
            <div className="bk-separator--20" />
            <p className="bk_pra">{data.service.title}</p>
            <div className="view-more-btn">
              <span>{data.service.description}</span>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

ServiceItemThree.propTypes = {
  data: PropTypes.object,
};

export default ServiceItemThree;
