import LayoutDefault from "layouts/LayoutDefault";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import helios from "data/helios.json";
import HeroBanner from "container/hero-banner/HeroCreativeAgency";
import {
  indigenousData,
  indigenousMoreInfo,
  indiServices,
  ourWork,
  IndiPartnerships,
  indiProjects1,
  indiProjects2,
  indiProjects3,
  // projectsDataIndi,
  IndiInvest,
} from "data/indigenous/indigenous";
import PortfolioGrid from "container/portfolio/pages/PortfolioGrid";
import MoreInfo from "components/more-info/MoreInfo";
import About from "container/about-us/AboutCreativeAgency";
import BigHeading from "components/big-heading";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Box from "components/icon-box/IconBoxItemOne";
// import { partnershipData } from "../../data/partnerships-page/partnerships";
// import LessInfo from "components/more-info/LeffInfo";
import bilcn from "../../assets/img/client/bilcn.jpeg";
import acden from "../../assets/img/client/acden.jpeg";

function Indigenous() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{`Indigenous | ${helios.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-2 template-font-3 bg_color--4"
        revealFooter={true}
      >
        <HeroBanner
          title={indigenousData.title}
          description={indigenousData.description}
          videoLink={indigenousData.videoLink}
          isUnmute={indigenousData.isUnmute}
        />
        <MoreInfo
          heading={indigenousMoreInfo.heading}
          text={indigenousMoreInfo.text}
          icon={indigenousMoreInfo.icon}
        />
        <About
          miniTitleBasic={indiServices.miniTitleBasic}
          description={indiServices.description}
        />
        <PortfolioGrid />
        <About
          miniTitleBasic={ourWork.miniTitleBasic}
          description={ourWork.description}
        />
        <MoreInfo
          id={IndiPartnerships.id}
          heading={IndiPartnerships.heading}
          text={IndiPartnerships.text}
          icon={IndiPartnerships.icon}
          link={IndiPartnerships.link}
        />
        <BigHeading data={indiProjects1} imgUrl={bilcn} />
        <BigHeading data={indiProjects2} imgUrl={acden} />
        <BigHeading
          data={indiProjects3}
          imgUrl="https://0901.nccdn.net/4_2/000/000/038/2d3/MLTCII-Logo-331x331.jpg"
        />
        {/* {projectsDataIndi.map((x) => {
          return <LessInfo heading={x.heading} text={x.text} icon={x.icon} />;
        })} */}
        {/* <div className="bg-white">
          <div className="container pt--50 pb--50">
            <div className="h-theme text-center">Completed Mandates</div>
            <Row>
              {partnershipData.map((partnershipsData) => (
                <Col key={partnershipsData.id} sm={6} lg={4}>
                  <Box data={partnershipsData} padding={true} border={true} />
                </Col>
              ))}
            </Row>
          </div>
        </div> */}
        <MoreInfo
          heading={IndiInvest.heading}
          text={IndiInvest.text}
          icon={IndiInvest.icon}
        />
      </LayoutDefault>
    </>
  );
}

export default Indigenous;
