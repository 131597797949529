import vid from "../../assets/videos/about.m4v";

// import dark from "../../assets/img/dark.jpg";
import Andrew from "../../assets/img/about/Andrew.jpeg";
import Sameer from "../../assets/img/about/Sameer.jpeg";
import Christopher from "../../assets/img/about/Christopher.jpeg";
import Colin from "../../assets/img/about/Colin.jpeg";
import eCarbon from "../../assets/img/about/ecarbon-logo.jpg";
import heliosx from "../../assets/img/about/heliosx.jpg";
import Manish from "../../assets/img/about/Manish.jpg";
import Scott from "../../assets/img/about/Scott.jpg";
import logo from "../../assets/img/logo/helios-logo.png";
export const aboutBannerData = {
  title: "About",
  description: "Know more about helios",
  videoLink: vid,
};

export const aboutParagraphData = {
  title: "About helios",
  logo: logo,
  more: [
    `Helios Corporation (Helios) was created in 2016 to raise the Environmental, Social and Governance (ESG) standard through positive environmental leadership initiatives. Management of Helios has significant international experience, and in 2016, decided to apply this expertise to our own First Nations in Canada. Our singular message to First Nations has consistently been, “Have you seized and capitalized on your rights as a sovereign Nation?’, which means, once First Nations assert themselves as sovereign Nations, the perspective on how to move forward fundamentally changes.`,
    `We apply our financial and technical expertise in partnerships with First Nations to empower Nations in a manner that the Government of Canada never expected. We strive to remove the dependency some Nations have with the Government of Canada by removing the inherent “strings” attached to Government grants.`,
    `Helios has a proven history of organic wealth creation with a focus on effective execution of innovative investment opportunities. Our laser focus has been on infrastructure, technology and environmental services.`,
    `Successful mandates through Indigenous Partnerships include the application of new technologies that protect the environment that have grown from 2 employees to over 70 within 6 months and significant increases in new revenues. Another partnership increased third party revenue for a Nation 300% within 10 months. Conception coupled with expert execution is what differentiates Helios, which is how we empower Nations with new opportunities for growth.`,
  ],
};

export const aboutTeamData = [
  {
    name: "Christopher Brown",
    designation: "Founder and CEO",
    email: "info@helioscorp.ca",
    location: "",
    id: 1,
    img: Christopher,
    desc: `Mr. Christopher Brown has 25 years of experience as a professional engineer working in the global capital markets and energy/infrastructure operations. His work history is equally divided between technical reservoir and operations experience combined with detailed financial modeling and capital markets experience. Most recently Mr. Brown has been focused on developing new Indigenous Partnership entities and empowering Nations with the latest in environmental technologies. He is one of the founders of Acden Helios Ltd. which provides avian deterrent services at Suncor and Imperial mine sites in the Oilsands. Mr. Brown was most recently Director, CEO and President of Huntington Exploration Inc. (Huntington)(HEI: TSXv) which was recapitalized from an energy company to an exploration mining company. Originally refinanced last November 2020 at $0.05/share, Mr. Brown successfully closed a number of transactions and recruited a new management team in April 2021 that increased shareholder value to $0.35/sh.  Mr. Brown has a Bachelor of Science in Chemical Engineering from the University of Calgary and completed the design and simulation work on a full-scale Methanol production facility.`,
  },
  {
    name: "Sameer Uplenchwar",
    designation: "Founder and CFO",
    email: "info@helioscorp.ca",
    location: "",
    id: 2,
    img: Sameer,
    desc: `Mr. Sameer Uplenchwar has over 15 years of financial and business development experience. Mr. Uplenchwar’s expertise is in financial structuring and modeling as well as energy banking. With significant depth of contacts within the Canadian and US energy sector Mr. Uplenchwar generates significant deal flow opportunities for investors. Previously, Mr. Uplenchwar served as Managing Director and Head of US Equity Research with GMP Capital LLC in Houston, Managing Director with Global Hunter Securities in Calgary/Houston.`,
  },
  {
    name: "Manish Bindal",
    designation: "Corporate Compliance Officer",
    email: "info@helioscorp.ca",
    location: "",
    id: 2,
    img: Manish,
    desc: `Mr. Bindal has worked in the Securities industry since 1985 and is responsible for all of the Helios Group of Companies’ regulatory filings, annual general meetings and corporate governance. He ensures all regulatory filings, disclosures, annual information forms, management information circulars and interim reports are in good order.  He also liaises with: transfer agents, lawyers, accountants, auditors, stock exchanges and securities regulators. His wealth of corporate governance experience assists the Board of Directors with: committee meetings, drafting materials and resolutions, managing logistics, maintaining corporate minute books, recording minutes, managing SEDAR filings, coordinating virtual meeting platforms and updating policies and procedures.`,
  },
  {
    name: "Colin Penner",
    designation: "Vice President of Operations",
    email: "info@helioscorp.ca",
    location: "",
    id: 4,
    img: Colin,
    desc: `Mr. Penner has over 20 years of business and operations management experience. He has extensive experience creating and supervising teams and engaging with multiple stakeholder groups during project implementation and operations. Mr. Penner has decades of experience working in the oil and gas, mining, and construction industries. As Field Supervisor for Acden Helios, he managed teams of biologists and operators for the avian deterrent program at Suncor’s Fort Hills oil sands mine. Colin is a graduate from the Haskayne School of Business at the University of Calgary.
    `,
  },
  {
    name: "Andrew Best",
    designation: "Director - Commercial Marketing & Indigenous Relations",
    email: "info@helioscorp.ca",
    location: "",
    id: 3,
    img: Andrew,
    desc: `Mr. Best has over 30 years of communication and Indigenous engagement experience. Mr. Best’s career began in Victoria, B.C. as a photo-journalist and went on to include public broadcasting as Manager of Northern Native Broadcasting, Yukon, television commercials for companies like Swiss Chalet and Kal Tire, and corporate video productions for every level of government and the Oil and Gas, Tourism, Forestry, Mining, Construction, Health and Aerospace Industries. His proven track record of clarifying goals, leveraging organizational strengths, developing creative strategies and executing major projects for print, television, radio and online campaigns has earned him a reputation for telling compelling stories that get results. His Indigenous work has helped educate corporations and governments about cultural values and traditions; and cultivated successful business relationships for the Inuvialiut’s Group of Companies, Chevron’s Kitimat LNG project and the 16 community partners along the Pacific Trails Pipeline. Andrew majored in English at the University of British Columbia before taking Journalism at Langara College.`,
  },
  {
    name: "Mat Goldsmith",
    designation: "Advisory Board",
    email: "info@helioscorp.ca",
    location: "",
    id: 5,
    img: "https://0901.nccdn.net/4_2/000/000/07d/95b/matg-304x405-304x405.jpg",
    desc: `Mr. Goldsmith is a partner of P5 Infrastructure LLC and has decades of experience in private equity, investment banking and institutional equity financings. His broad experience includes: creating, negotiating, structuring, syndicating and monetizing private equity investments, all aspects of private equity transactions from negotiating business acquisitions to partnering with management teams, negotiating shareholders’ agreements, due diligence, raising debt, monetizing investments through trade sales, preparing companies for IPO and taking investments through the IPO process.
    Mr. Goldsmith was a co-founder of Manabi S.A., former Board of Director and co-founder, CFO and former Board of Director of HRT Participacoes em Petroleo S.A. Mr. Goldsmith was also Managing Director at BMO Capital Markets, where he focused on Energy, Infrastructure and Mining.
    `,
  },
];

export const projectsDataIndi = [
  {
    heading: "eCarbon Corp.",
    text: "eCarbon will play an integral role in the Clean Energy Transition by leveraging decades of Energy, Finance, Infrastructure, Indigenous and Environmental expertise. It will acquire a portfolio of: Lithium assets to capitalize on near-term opportunities with long term fundamentals, Direct Lithium Extraction technologies and a pipeline of Renewable Energy opportunities.",
    icon: eCarbon,
  },
  // {
  //   heading: "HeliosX",
  //   text: "HeliosX is an integrated lithium exploration company incorporating the latest in Direct Lithium Extraction (“DLE”) technologies to unlock 560,000 acres of potential commercial opportunities in Jujuy (Argentina), Nevada (USA) and Alberta (Canada). The Company has commercial agreements to utilize the latest in environmental technologies to remediate old mine tailings while extracting commercial precious metals, and plans to own and operate the first iodine-based gold concentrate extraction facility in North America.",
  //   icon: heliosx,
  // },
  {
    heading: "Seven emirates",
    text: "Seven Emirates is a wholly own subsidary of SBK Holding which is the holding company of HH Dr. Sheikh Sultan Bin Khalifa Bin Zayed Al Nahyan. The partnership is to introduce new opportunities to the United Arab Emirates that would be considered valuable services. Helios Corporation's relationship could facilitate many investment opportunities within the UAE. In addition, numerous opportunities for strategic mandates with ADNOC currently available. Please contact us for further information",
    icon: "https://0901.nccdn.net/4_2/000/000/056/7dc/sbk-521x188-320x115.jpg#RDAMDAID935637",
  },
  // {
  //   heading: "Kaizen Oil Corporation",
  //   text: "Kaizen Oil Corporation has offices in California and Nevada and are partnered with Helios Corporation in the Canzen Oil joint venture. Kaizen Oil has extensive institutional contacts to facilitate energy transactions in Canada. Currently partnered on a 1,000 boe/d (60% oil) exclusive investment opportunity in Alberta, Canada. Kaizen Oil continues to seek new acquisition opportunities in the Western Canadian Sedimentary Basin.",
  //   icon: "https://0901.nccdn.net/4_2/000/000/05a/a3f/kaizen-320x28.jpg#RDAMDAID761455",
  // },
  {
    heading: "ETIA Ecotechnologies",
    text: "For next generation renewable technology to convert biomass to electricity or renewable natural gas (RNG), highly recommend partnering with ETIA Ecotechnologies based out of France. ETIA brings proprietary pyrolysis technology combined with leading edge process engineering design for pyrolysis gas cleanup. Applications in both British Columbia to maximize both carbon credits combined with FortisBC commitment of upwards of $30/GJ for RNG 20-year contracts, as well as in California for RNG contracts that can be in excess of US$35/GJ to capitalize on the technology.",
    icon: "https://0901.nccdn.net/4_2/000/000/03f/ac7/ETIA-2-160x152.jpg#RDAMDAID1540183",
  },
  // {
  //   heading: "Titan Oil Recovery",
  //   text: `Titan Oil Recovery partnership is done through Canzen Oil. Canzen holds the preferential agreement for the use of Titan's patented EOR application in Canada. The joint venture was formed to specifically acquire undervalued Canadian oil assets.
  //   Titan process is an organic, environmentally friendly, microbial enhanced oil recovery process. Titan's recovery process works with the existing natural ecology within the oil reservoir to stimulate the microbial growth and improve the recovery.`,
  //   icon: "https://0901.nccdn.net/4_2/000/000/06b/a1b/b02a4d33708d48abb9e3756ff5898f7a-191x217-191x217.jpg",
  // },
  {
    heading: "United Oil & Gas",
    text: "UOGC (uogc.com) has a proven track record since 1991 of providing geosteering, geo-modeling and reservoir engineering services and software. The company has completed projects both in North America and International, and has developed all of its software and technology in-house. UOGC is an expert at reservoir studies and its proprietary SMART4D technology has been involved with over 1000 horizontal wells. The SMART4D geosteering software has been developed to allow operators and exploration companies to geosteer their own wells.",
    icon: "https://0901.nccdn.net/4_2/000/000/081/4ce/uogc-320x74.jpg#RDAMDAID1439949",
  },
];
