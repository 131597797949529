import React from 'react';
import {Link} from "react-router-dom";

const Logo = ({logo, className}) => {
    return (
        <div className={`logo ${className}`}>
            <Link to={process.env.PUBLIC_URL + "/"}>
                < img src={logo} alt="logo"/>
            </Link>
        </div>
    );
};

export default Logo;