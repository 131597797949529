import triangle_bg1 from "../../assets/img/home/profitable.jpg";
import triangle_bg2 from "../../assets/img/home/sustainable.jpg";
import triangle_bg3 from "../../assets/img/home/equitable.jpg";
import dark from "../../assets/img/dark.jpg";
import vid from "../../assets/videos/home.m4v";
export const valuesData = [
  {
    id: 1,
    img: triangle_bg3,
    content: " fair, just and reasonable for all parties",
    heading: "",
    subheading1: "Equitable",
    subheading2: "Sub  3",
    count: 1,
    className: "",
    color: "#000",
  },
  {
    id: 2,
    img: triangle_bg1,
    content: "generates profit by delivering a beneficial product or service",
    heading: "",
    subheading1: "Profitable",
    subheading2: "Fiee",
    count: 2,
    className: "",
    color: "#000",
  },
  {
    id: 3,
    img: triangle_bg2,
    content:
      "meets our needs without compromising future generations ability to meet their needs",
    heading: "",
    subheading1: "Sustainable",
    subheading2: "Sub 2",
    count: 3,
    className: "",
    color: "#000",
  },
];

export const defaultValues = {
  id: 0,
  img: dark,
  content: "",
  heading: "",
  yellowText: "",
  subheading1: "Helios Pillars",
  subheading2: "Fiee",
  count: 1,
  className: "",
  color: "#000",
};

export const homePageData = {
  whoWeAre: {
    title: "Who we are",
    miniTitleBasic: "Helios",
    description:
      "We believe that energy is the lifeblood and minerals are the finite treasure we need to build a sustainable world. We know our current trajectory is unsustainable, so we focus decades of expertise in Energy, Power, Biomass and Capital Markets with the latest environmental technology to achieve more, using less.",
    button: "More Details",
    link: "/",
  },
  ourVision: {
    title: "OUR VISION",
    miniTitleBasic: "",
    miniTitleStyle: "Profitable, Sustainable, Equitable",
    description:
      "If it’s not profitable - it’s not sustainable.  And if we don’t all benefit – it’s not equitable. Helios is driven to harness technology to maximize resource potential and benefit everyone, particularly Indigenous nations who have too long been sidelined and left with environmental degradation.  Imagine a world where we achieve more, for everyone, and leave an environmental legacy we can be proud of.",
  },
};

export const ourValues = {
  title: "OUR VISION",
  miniTitleBasic: "Profitable, Sustainable",
  miniTitleStyle: "and Equitable",
  description:
    "If it’s not profitable and widely accepted, positive environmental impact investments will not be sustainable. Helios is driven to harness technology to maximize resource potential and benefit everyone, particularly Indigenous Nations who have historically been excluded from consultation and participation on commercial activities that directly impact the environment. We are passionate about creating a world where we reduce environmental impacts and unlock a generational legacy of future environmental development opportunities.",
};
export const homepageBannerData = {
  title: "Helios",
  videoLink: vid,
  description:
    "Montage of beauty environmental scenics, Indigenous, technology, resource developments",
};
export const menuData = [
  {
    title: "Home",
    id: "1",
    isDropdown: true,
    link: "/",
    subHeading: [
      {
        title: "Homepage 1",
        id: "1.1",
        isDropdown: false,
        link: "/",
        menuItems: [
          {
            title: "Business",
            id: "1.1.1",
            link: "/",
          },
          {
            title: "Creative",
            id: "1.1.1",
            link: "/",
          },
          {
            title: "Random",
            id: "1.1.1",
            link: "/",
          },
        ],
      },
    ],
  },
  {
    title: "Pages",
    id: "1",
    isDropdown: true,
    link: "/",
    subHeading: [
      {
        title: "Homepage pages",
        id: "1.1",
        isDropdown: false,
        link: "/",
        menuItems: [
          {
            title: "Business",
            id: "1.1.1",
            link: "/",
          },
          {
            title: "Creative",
            id: "1.1.1",
            link: "/",
          },
          {
            title: "Random",
            id: "1.1.1",
            link: "/",
          },
        ],
      },
    ],
  },
];
