import LayoutDefault from "layouts/LayoutDefault";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import helios from "data/helios.json";
import HeroBanner from "container/hero-banner/HeroCreativeAgency";
import { projectsBannerData } from "data/projects-page/projects";
import BigHeading from "components/big-heading";
import { projects } from "../../data/projects-page/projects";

function Projects() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{`Partnerships | ${helios.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-2 template-font-3 bg_color--4"
        revealFooter={true}
      >
        <HeroBanner
          title={projectsBannerData.title}
          description={projectsBannerData.description}
          videoLink={projectsBannerData.videoLink}
        />
        <BigHeading scroll={true} data={projects} />
      </LayoutDefault>
    </>
  );
}

export default Projects;
