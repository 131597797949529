import React from "react";
import socials from "data/socials";

const SocialIcons = () => {
  return (
    <ul className="social-icon icon-solid-rounded icon-size-medium text-center move-up wow">
      {socials.map((social, index) => (
        <li className={social.media}>
          <a
            key={"socialIcon" + index}
            target="_blank"
            href={`https://${social.media}.com/${social.username}`}
          >
            <i className={`fa fa-${social.media}`} />
          </a>
        </li>
      ))}
    </ul>
  );
};
export default SocialIcons;
