import { contactBannerData } from "data/contact-page";
import LayoutDefault from "layouts/LayoutDefault";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "container/hero-banner/HeroCreativeAgency";
import helios from "data/helios.json";
import ContactInfo from "container/contact/ContactTwo";
import ContactInformation from "container/contact/ContactInfoOne";
import InfoContainer from "components/infoContainer/InfoContainer";

function Contact() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{`Contact | ${helios.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-2 template-font-3 bg_color--4"
        revealFooter={true}
      >
        <HeroBanner
          title={contactBannerData.title}
          description={contactBannerData.description}
          videoLink={contactBannerData.videoLink}
        />
        <ContactInformation />
        {/* <ContactInfo />         */}
      </LayoutDefault>
    </>
  );
}

export default Contact;
