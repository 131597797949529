import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Logo from "components/header/elements/logo";
import Widget from "components/widget";
import logo from "../../assets/img/logo/helios-logo.png";
import Fade from "react-reveal/Fade";
import SocialIcons from "components/socials/SocialIcons";
const FooterOne = ({ className }) => {
  let subscription = localStorage.getItem("subscription") || false;
  return (
    <>
      <div className="footer-invest-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-8">
              <Fade bottom>
                <h3 className="footer-subscribe-title">Invest with HELIOS</h3>
                <p className="invest-description">
                  Look at the investment opportunities in HELIOS.
                </p>
                {/* <Link to="/projects" className="footer-invest-link"> */}
                <a href="mailto: investors@helioscorp.ca" className="footer-invest-link">
                  <span className="invest-label">Invest with Helios</span>
                  <span className="invest-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </a>
                {/* </Link> */}
              </Fade>
            </div>
            <div className="col-sm-12 col-lg-4">
              <Fade bottom>
                <h3 className="footer-subscribe-title">Partner with Helios</h3>
                <p className="invest-description">Change the world with us.</p>
                {/* <a href="mailto: partners@helioscorp.ca" className="footer-invest-link"> */}
                <Link to="/indigenous#partnerships" className="footer-invest-link">
                  <span className="invest-label">Partner with Helios</span>
                  <span className="invest-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </Link>

                {/* </a> */}

              </Fade>
            </div>
            {/* <div className="col-sm-12 col-lg-4">
              <Fade bottom>
                <h3 className="footer-subscribe-title">Latest Projects</h3>
                <p className="invest-description">
                  Stay up to date with HELIOS'S projects in all sectors.
                </p>
                <Link to="/projects" className="footer-invest-link">
                  <span className="invest-label"> Explore our projects</span>
                  <span className="invest-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </Link>
              </Fade>
            </div> */}
          </div>
        </div>
      </div>
      {subscription && (
        <div className="footer-subscribe-container">
          <Fade bottom>
            <div className="invest-subscribe-content row">
              <div className="footer-subscribe-invest col-sm-12 col-lg-4">
                <h3 className="footer-subscribe-title">
                  Subscribe and be the first to know
                </h3>
                <p className="invest-description">
                  about the latest developments and exciting news on how we are
                  shaping the future!
                </p>
              </div>
              <div className="footer-subscribe-invest col-sm-12 col-lg-4">
                <form>
                  <input
                    type="text"
                    className="subcribe-input"
                    placeholder="Email address"
                    required={true}
                  />
                  <div className="submitContainer">
                    <input
                      type="text"
                      className="subcribe-input"
                      placeholder="Email address"
                      required={true}
                    />
                    <div className="submitContainer">
                      <input
                        type="submit"
                        value="Sign up"
                        name="subscribe"
                        className="submit-button"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="footer-social-invest col-sm-12 col-lg-4">
                <div className="row">
                  <h3 className="footer-social-title">Follow HELIOS:</h3>
                  <div className="social-links ">
                    <SocialIcons />
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      )}
      <footer
        className={`page-footer pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--5 pr_sm--5 ${className}`}
      >
        <div className="bk-footer-inner pl--60 pt--150 pb--30 pt_sm--100">
          <Row>
            <Col xs={12} lg={4}></Col>{" "}
            <Col>
              <div className="copyright text-var-2">
                <Row className="align-items-center">
                  <Widget
                    wrapClasses="text-var--2 footer-align-desktop"
                    title={null}
                  >
                    <Logo logo={logo} className="footer-logo" />
                    <div className="footer-inner">
                      {/* <p>
                        Montage of beauty environmental scenics, Indigenous,
                        technology, resource developments
                      </p> */}
                    </div>
                  </Widget>
                  <Col xs={6} className="mt--30">
                    <div className="copyright-left text-md-left ">
                      <ul className="bk-copyright-menu d-flex bk-hover justify-content-md-start flex-wrap flex-sm-nowrap">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/about-us"}>
                            About
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/projects"}>
                            Projects
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/contact"}>
                            Contact us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </>
  );
};

FooterOne.defaultProps = {
  className: "bg_color--3",
};

export default FooterOne;
