import { aboutBannerData } from "data/about-page";
import LayoutDefault from "layouts/LayoutDefault";
import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "container/hero-banner/HeroCreativeAgency";
import helios from "data/helios.json";
import AboutDescription from "./AboutDescription";
import AboutDescriptionTeam from "./AboutDescriptionTeam";
import Description from "components/description/Description";
import CarouselContainer from "components/slider/CarouselContainer";
import LessInfo from "components/more-info/LeffInfo";
import data from "../../components/slider/carouselData.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Box from "components/icon-box/IconBoxItemOne";
import { partnershipData } from "../../data/partnerships-page/partnerships";

import { aboutParagraphData, projectsDataIndi } from "data/about-page";
function AboutUs() {
  const scrollRef = useRef(null);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{`About | ${helios.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-2 template-font-3 bg_color--4"
        revealFooter={true}
      >
        <HeroBanner
          title={aboutBannerData.title}
          description={aboutBannerData.description}
          videoLink={aboutBannerData.videoLink}
        />
        <div className="bg-white">
          <AboutDescription aboutParagraphData={aboutParagraphData} />
          <AboutDescriptionTeam />
          {/* <Description reference={scrollRef} />
          <CarouselContainer data={data} /> */}
        </div>
        <div
          style={{
            color: "#222222",
            fontSize: "3rem",
            paddingBottom: "15px",
            textDecoration: "underline",
            background: "#F9F7F1",
            marginBottom: "unset",
            textAlign: "center",
          }}
        >
          Partnerships
        </div>
        {projectsDataIndi.map((x) => {
          return <LessInfo heading={x.heading} text={x.text} icon={x.icon} />;
        })}

        <div className="bg-white">
          <div className="container pt--50 pb--50">
            <div className="h-theme text-center">Completed Mandates</div>
            <Row>
              {partnershipData.map((partnershipsData) => (
                <Col key={partnershipsData.id} sm={6} lg={4}>
                  <Box data={partnershipsData} padding={true} border={true} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </LayoutDefault>
    </>
  );
}

export default AboutUs;
