import LayoutDefault from "layouts/LayoutDefault";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import helios from "data/helios.json";
import HeroBanner from "container/hero-banner/HeroCreativeAgency";
import { investorsBannerData } from "data/investors-page/investors";

function Investors() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{`Partnerships | ${helios.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-2 template-font-3 bg_color--4"
        revealFooter={true}
      >
        <HeroBanner
          title={investorsBannerData.title}
          description={investorsBannerData.description}
          videoLink={investorsBannerData.videoLink}
        />
      </LayoutDefault>
    </>
  );
}

export default Investors;
