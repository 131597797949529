import svg1 from "../../assets/svg/svgexport-2.svg";
import svg2 from "../../assets/svg/svgexport-3.svg";
import svg3 from "../../assets/svg/svgexport-4.svg";
import svg4 from "../../assets/svg/svgexport-5.svg";

import asvg1 from "../../assets/svg/svgexport-2.svg";
import asvg2 from "../../assets/svg/svgexport-3.svg";

import pdfHeliosX from "../../assets/pdf/heliosx.pdf";

// Images for bg
import bg1 from "../../assets/img/sectors/energy/energy-main.png";
import bg2 from "../../assets/img/sectors/carbon/carbon-main.jpg";
import bg3 from "../../assets/img/sectors/fiber/fiber-main.jpg";
import bg4 from "../../assets/img/sectors/mining/mining-main.jpg";

import energy1 from "assets/img/sectors/energy/1.jpeg";
import energy2 from "assets/img/sectors/energy/2.jpg";
import energy3 from "assets/img/sectors/energy/3.jpg";
import carbon1 from "assets/img/sectors/carbon/1.jpg";
import carbon2 from "assets/img/sectors/carbon/2.jpg";
import carbon3 from "assets/img/sectors/carbon/3.png";
import fiber1 from "assets/img/sectors/fiber/1.jpg";
import fiber2 from "assets/img/sectors/fiber/2.jpg";
import fiber3 from "assets/img/sectors/fiber/3.png";
import mining1 from "assets/img/sectors/mining/1.jpg";
import mining2 from "assets/img/sectors/mining/2.jpg";
import mining3 from "assets/img/sectors/mining/3.jpg";

import secEnergy from "assets/svg/energy.png";
import secCarbon from "assets/svg/carbon.png";
import secFiber from "assets/svg/fiber.png";
import secMining from "assets/svg/mining.png";
import heliosx from "assets/img/client/heliosx.jpg";

const sectorsData = {
  "/sector/energy": {
    to: "/sector/energy",
    innersvgdbefore: secEnergy,
    innersvgdafter: secEnergy,
    bgimg: bg1,
    id: "3.1.1",
    head: "Our Services",
    title: "Energy",
    paragraphContent:
      '<p class="wrapped-text">The Helios Team bring 100 years of technical, financial modeling, production sharing agreements, concession or service agreements to every investment opportunity and project. Our professional engineers, geologists and geophysicists can be deployed domestically or internationally and have completed technical and financial modeling in more than 85 countries. Much of our work has ensured global institutions make appropriate risk/reward investment decisions based on comprehensive analysis of each jurisdiction.<br /> <br />Helios has partnered on low-risk energy investments that are projected to produce reliable income for over 50 years with no long-term liability risk.</p> <p class="wrapped-text">Helios has secured 25 barrels per day of oil royalties in Western Canada and will continue to strategically acquire additional production.</p> <p class="wrapped-text">Our partnership with United Oil and Gas Consulting provides enhanced Geo-steering, Geo-modeling and Reservoir Engineering Services and software. The application of proprietary SMART4D technology and interactive software tools provide unparalleled Geostatistical Mapping, Reservoir and Petrophysical Characterization and Geo-steer the wellbore in real time within the 3D model to analyze wells and reservoirs.</p>',
    listContent: [],
    link: "/sector/energy",
    icon: secEnergy,
    image: bg1,
    color2: "#205d66",
    color1: "#2c818d",
    sectorsImgs: [
      {
        id: 1,
        thumb: energy1,
      },
      {
        id: 2,
        thumb: energy3,
      },
      {
        id: 3,
        thumb: energy2,
      },
    ],
    serviceData: [
      {
        title: "Invest With Low Risk",
        description:
          "Provide capital to junior energy companies to re-invest in low-risk field development investments",
      },
      {
        title: "Partnership Opportunities",
        description:
          "Indigenous Partnership opportunities on energy development investments and Microbial Enhanced Oil Recovery Partnerships",
      },
      {
        title: "Intellectual Property",
        description:
          "Domestic and international field development Audit and Design",
      },
      {
        title: "Production Sharing",
        description:
          "Review and modelling of Production Sharing Agreements, Concession Agreements and Service Agreements. These include technical Audit of Cost Recovery",
      },
    ],
  },
  "/sector/carbon-reduction": {
    to: "/sector/carbon-reduction",
    innersvgdbefore: secCarbon,
    innersvgdafter: secCarbon,
    bgimg: bg2,
    id: "3.1.2",
    head: "eCarbon at a Glance",
    title: "CARBON REDUCTION",
    link: "/sector/carbon-reduction",
    icon: secCarbon,
    image: bg2,
    color2: "#0086b3",
    color1: "#0096c7",
    paragraphContent:
      '<p class="wrapped-text">We want to reduce carbon emissions from bug killed forest fires by converting it to Renewable Natural Gas. </br></br> After years of research, and a partnership with engineering company HATCH, we plan to commercialize the conversion of wood waste to Renewable Natural Gas (RNG). The next steps are: conduct Front End Engineering Design (FEED) work, identify a location and Indigenous partner.</p>',
    listContent: [],
    sectorsImgs: [
      {
        id: 1,
        thumb: carbon1,
      },
      {
        id: 2,
        thumb: carbon2,
      },
      {
        id: 3,
        thumb: carbon3,
      },
    ],
    serviceData: [
      {
        title: "Our Mission",
        description:
          "Our Mission is to leave the Planet better than we found it and we will achieve that by employing innovative technology and creating equitable Indigenous partnerships that create multi-generational wealth.",
      },
      {
        title: "Our Responsibility ",
        description:
          "We have a responsibility to not just identify problems, but create solutions. We build innovative green projects that convert wood waste to Renewable Natural Gas and create socio-economic opportunities that empower Indigenous communities.",
      },
      {
        title: "Clean Transition",
        description:
          "We will reduce carbon emissions and actively participate in the Clean Transition through our expertise in Lithium exploration and Direct Lithium Extraction technologies.",
      },
      {
        title: "Our Goal",
        description:
          "Leave the planet better than we found it by identifying, funding and building green projects that substantively improve quality of life and our environmental legacy.",
      },
    ],
  },
  "/sector/fiber-infrastructure": {
    to: "/sector/fiber-infrastructure",
    innersvgdbefore: secFiber,
    innersvgdafter: secFiber,
    bgimg: bg3,
    id: "3.1.3",
    head: "Our Services",
    title: "Fiber Infrastructure",
    link: "/sector/fiber-infrastructure",
    icon: secFiber,
    image: bg3,
    color2: "#996600",
    color1: "#b37700",
    extra: {
      miniTitleBasic: "Our Clients",
      description: [
        "Advised the Alberta Electricity System Operator (AESO) on the design and implementation of a 300 MW Renewable Electricity Program (REP)",
        "Guided Indigenous Nations on how to achieve autonomous power generation",
        "Designed Renewable investment programs for wind and solar projects below 5 MW’s for the Government of Alberta",
        "Designed a $400 Million Loan Guarantee Program for Renewable Investment projects over 5 MW for the Government of Alberta",
        "Provided advisory services to KT Corporation (formerly Korean Telecom) for Pre-Feasibility study of a 40 MW power facility in Alberta. Encouraged an Indigenous partnership to accelerate the environmental review and approval process",
        "Created $100 million Term Sheet options for an Indigenous Nation that acquired the largest equity participation in one of Canada's top 50 infrastructure projects",
      ],
    },
    paragraphContent:
      '<p class="wrapped-text">Our strategic partnerships with Engineering, Design and Technical Field Service firms provide Project Management and Advisory Services for next generation fiber infrastructure projects. We also provide creative financing solutions to Indigenous Nations and can project manage the entire process to ensure cost effective execution and quality verification.</p>',
    listContent: [],
    sectorsImgs: [
      {
        id: 1,
        thumb: fiber1,
      },
      {
        id: 2,
        thumb: fiber3,
      },
      {
        id: 3,
        thumb: fiber2,
      },
    ],
    serviceData: [
      {
        title: "Provide Services Across Canada",
        description:
          "Ultimate goal is sovereign Indigenous ownership of large-scale internet fiber across Canada (Fiber today is what oil pipelines were 50 years ago).",
      },
      {
        title: "Consult And Partner With Nations",
        description:
          "Consult and partner with Nations on Nation fiber development. Complete survey work, Feasibility study, source necessary capital",
      },
      {
        title: "Provide Own Fiber Infrastructure",
        description:
          "Helios has a model where Indigenous Nations own and operate their own fiber infrastructure.",
      },
      {
        title: "Micro-grid Management Renewable Technology",
        description:
          "Develop power system and micro-grid management Renewable Technology Integration, Power Generation Facilities, Data center development",
      },
    ],
  },
  "/sector/mining-extraction": {
    to: "/sector/mining-extraction",
    innersvgdbefore: secMining,
    innersvgdafter: secMining,
    bgimg: "/www/images/sectors/mining/banner_page.png",
    id: "3.1.4",
    head: "Our Services",
    title: "Mining and Extraction",
    link: "/sector/mining-extraction",
    icon: secMining,
    image: bg4,
    color2: "#e0bd5e",
    color1: "#b99732",
    extraMore: [
      // {
      //   title: "Dajin Lithium Corp",
      //   link: "www.dajin.ca",
      //   url: "https://www.dajin.ca",
      //   logo: "https://dajin.ca/static/media/logo.37fd99c1.png",
      //   more: [
      //     `Under HeliosX, a managed entity by Helios management group, we are working to partner with Dajin Lithium Corp (Dajin). Dajin holds a 49% interest in concessions or concession applications held by Dajin Resources S.A. (Dajin S.A.) in Jujuy Province, Argentina that were acquired in regions known to contain brines with Lithium, Potassium and Boron values.`,
      //     `These land holdings exceed 93,000 hectares (230,000 acres) and are primarily located in the Salinas Grandes and Guayatayoc salt-lake basins. Dajin is partnered with Pluspetrol Resources Corporation B.V. who through an Earn-in agreement have earned a 51% interest in Dajin S.A. with the expenditure of US$1,250,000 on the concessions and paying US$600,000 to Dajin. The initial 2018 Salinas Grandes exploration program on 4,400 hectares (10,873 acres) on the San Jose-Navidad minas, where 25 near surface Lithium brine samples were taken, returned excellent Lithium assays results ranging from 281 mg/l to 1,353 mg/l averaging 591 mg/l`,
      //     `In the United States, Dajin holds a 100% interest in 403 placer claims covering 7,914 acres (3,202 hectares) in the Teels Marsh Valley of Mineral County, Nevada. These claims are known to contain Lithium and Boron values. Dajin has entered into an Earn-in Agreement with Lone Mountain Resources, LLC, a privately held US Lithium exploration and development company affiliated with Lilac Solutions, Inc. Lilac is a well-funded Lithium extraction company located in Oakland, California. LMR can earn a 75% interest in Alkali Lake Lithium project with project related expenditures of US$200,000. An exploration well is to be drilled to a depth of 1,500 feet`,
      //   ],
      // },
      {
        title: "HeliosX Corp",
        logo: heliosx,
        // link: "www.heliosx.ca",
        // url: "https://www.heliosx.ca",
        more: [
          `Management Team created HeliosX Lithium & Technologies Corp and grew the asset base to more than 830,000 acres of highly prospective lithium Brine assets in Argentina, the USA and Canada.  Partnered with industry leaders in Direct Lithium Extraction technology and conducted detailed geological modelling and Brine production simulation and optimization. `,
        ],
        // moreinfo: "www.heliosx.ca",
      },
      {
        title: "EnviroMetal Technologies inc.",
        link: "www.envirometal.com",
        url: "http://www.envirometal.com",
        logo: "https://envirometal.com/wp-content/uploads/2021/07/em-logo-green.png",
        more: [
          `EnviroMetal’s proprietary and patented chemistry technology extracts precious and other valuable metals from conventional mine concentrates and printed circuit board assemblies. Envirometal’s proprietary technology is cost-effective, environmentally sustainable and a domestic alternative to the use of cyanide and smelters for the recovery of gold from primary and secondary sources.`,        ],
      },
      {
        title: "EnviroGold Global",
        // link: "www.envirogoldglobal.com",
        // url: "https://www.envirogoldglobal.com",
        // logo: "https://www.envirogoldglobal.com/wp-content/uploads/2021/02/web_logo.png",
        more: [
          `EnviroGold leverages proprietary technology, operational knowledge and an agile, efficient culture to recover valuable metals, recharge critical natural resources and accelerate the world’s transition to a sustainable circular resource economy. They are strategically positioned to sustainably supply the increasing demand for precious, critical and strategic metals by profitably reclaiming precious metals from mine tailings and waste streams.`,
        ],
      },
    ],
    extra: {
      miniTitleBasic: "OUR FOCUS",
      logo: "",
      description: [
        "Will partner with gold producers and Indigenous nations to create an environmentally benign solution to refining gold concentrate with cyanide, or shipping it, and the attendant pollution, to China.",
          "Partnering with industry and Indigenous nations to recover high value metals, eliminate contaminants and remediate surface tailings. A portion of the profits will be allocated to the Nation whose Traditional Territory has been impacted. ",
      ],
    },
    paragraphContent:
      // '<p class="wrapped-text">Seeking to partner with gold producers to provide an environmental alternative to refining gold concentrates with cyanide (usually shipped to China for processing).</p><p class="wrapped-text">Partnering with industry and Indigenous Nations to clean up and remediate surface tailings. Our technology allows for enhanced recovery of high value metals as well as the removal of contaminants. A portion of profits will be allocated to the impacted Nation.</p><p class="wrapped-text">Utilize the technology for lithium brine extraction opportunities in Alberta (Canada), Nevada (United States) and Argentina.</p><p class="wrapped-text">Helios has acquired over 310,000 acres of prospective lithium rights in Alberta through its partnership entities Fox Creek Lithium Corp. and HeliosX.</p>',
      '',
    listContent: [],
    sectorsImgs: [
      {
        id: 1,
        thumb: mining1,
      },
      {
        id: 2,
        thumb: mining3,
      },
      {
        id: 3,
        thumb: mining2,
      },
    ],
    serviceData: [
      {
        title: "Strong Restructuring Company",
        description:
          "Proven track record restructuring public companies to maximize shareholder value. Recently restructured Huntington Exploration (HEI-TSXv) from an energy company to an exploration mining company. Raised $1.5 million for Huntington at a price of $0.05/share with a full warrant $0.05/share.  Within four months we restructured and handed control to a new management team at $0.35/share.",
      },
      {
        title: "Experienced Helios Team",
        description:
          "Proven environmental experience that grew avian detection and deterrence operations to more than 100 employees at Suncor and Imperial mine sites through an Indigenous partnership.",
      },
      {
        title: "Equitable Developments",
        description:
          "Proven track record ensuring Indigenous Nations are properly compensated for developments on treaty lands and their voices actively guide responsible development.",
      },
      {
        title: "Supporting Latest Environmental Technology",
        description:
          "Helios has acquired the latest in environmental technology to maximize gold extraction (without the use of cyanide) and other high value metals, such as lithium.",
      },
    ],
  },
};

export default sectorsData;
