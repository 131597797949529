import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import AboutThumbOne from "components/about-us/thumbnail/AboutThumbOne";
// import aboutThumb from "assets/img/home/side-1.jpg";
import AboutContentOne from "components/about-us/content/AboutContentOne";

const AboutCreativeAgency = (props) => {
  const { title, miniTitleBasic, miniTitleStyle, description, button, link } =
    props;
  return (
    <div className="full-container">
      <div id="scrollIntoId" className="bk-about-area">
        <div className="about-area-bg section-ptb-100">
          <Container>
            <Row className="about--creative align-items-center">
              {/* <Col xs={12} lg={6}>
                <AboutThumbOne thumb={aboutThumb} />
              </Col> */}

              <Col className="mt_md--40 mt_sm--40">
                <AboutContentOne
                  title={title}
                  heading={`${miniTitleBasic} ${
                    miniTitleStyle ? miniTitleStyle : ""
                  }`}
                  content={description}
                  btnText={button}
                  btnLink={link}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default AboutCreativeAgency;
