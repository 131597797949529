import CardsSections from "components/cards-section";
import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";

function BigHeading(props) {
  const { data, scroll, imgUrl } = props;
  return (
    <>
      {scroll ? <div id="scrollIntoId"></div> : ""}
      {data.map((x) => {
        return (
          <>
            <div className="bg-big--heading">
              <div className="container">
                <div className="indigenous-project">
                  {imgUrl && (
                    <img
                      src={imgUrl}
                      alt="project"
                      className="indigenous-image-project"
                    />
                  )}
                  <div>
                    <Fade bottom>
                      <div className="indigenous-font-project">
                        <p className="bg-big--heading--t indigenous-font-project">
                          {x.headingBlack1}&nbsp;
                          {x.headingBlack2}
                        </p>
                      </div>
                    </Fade>

                    <Fade bottom>
                      <div>
                        <p className="bg-big--heading--t golden--text indigenous-font-project">
                          {x.headingGold1} &nbsp;
                          {x.headingGold2}
                        </p>
                      </div>
                    </Fade>
                  </div>
                </div>

                <div className="bg-big--heading--t-container">
                  <Fade bottom>
                    <div
                      className={`${
                        x.para2
                          ? "bg-big--heading--t--l"
                          : "bg-big--heading--t--l-full"
                      }`}
                    >
                      {x.para1}
                    </div>
                  </Fade>

                  {x.para2 ? (
                    <Fade bottom>
                      <div className="bg-big--heading--t--l">{x.para2}</div>
                    </Fade>
                  ) : null}
                </div>
                {x.para3 || x.para4 ? (
                  <div className="bg-big--heading--t-container">
                    <Fade bottom>
                      <div className="bg-big--heading--t--l">{x.para3}</div>
                    </Fade>

                    <Fade bottom>
                      <div className="bg-big--heading--t--l">{x.para4}</div>
                    </Fade>
                  </div>
                ) : (
                  ""
                )}
                {x.link && (
                  <Fragment>
                    <a
                      href={x.link}
                      style={{ color: "#b89535", textDecoration: "none" }}
                    >
                      Learn more >
                    </a>
                    <br />
                    <br />
                  </Fragment>
                )}
              </div>
              {x.cardsData ? <CardsSections cardsData={x.cardsData} /> : ""}
            </div>
          </>
        );
      })}
    </>
  );
}

export default BigHeading;
