import React from "react";

const IconBoxItemOne = ({ data, icon, border, padding }) => {
  return (
    <div
      className={`icon-box mt--30 text-center ${
        border ? "" : "no-border"
      } ${!border && padding ? "with-padding" : ""}`}
    >
      <div className="inner">
        <div className="icon">
          <img src={data.icon} alt="country" />
        </div>
        <div className="content">
          <div className="h-theme-xs">{data.title}</div>
          <p className="bk_pra">{data.content}</p>
        </div>
      </div>
    </div>
  );
};

export default IconBoxItemOne;
