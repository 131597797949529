import React from "react";
import Fade from "react-reveal/Fade";

function LessInfo(props) {
  const { heading, text, icon } = props;
  return (
    <div className="less-info--container"> 
      <div className="container">
        <div className="more-info--top row">
          <div className="more-info--top-left col-md-4 col-lg-3">
            <Fade bottom>
              <img
                className="less-info--top-left-icon mb-3"
                alt={heading}
                src={icon}
              />
            </Fade>
          </div>
          <div className="less-info--top-right col-md-8 col-lg-9">
            <Fade top>
              <div className="more-info--top-right-top h-theme-sm mb-2">{heading}</div>
            </Fade>
            <Fade right>
              <div className="more-info--top-right-bottom p-theme">{text}</div>
            </Fade>
          </div>
        </div>
        <div className="more-info--bottom"></div>
      </div>
    </div>
  );
}

export default LessInfo;
