import { aboutTeamData } from "data/about-page";
import React from "react";
import { Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";

function AboutDescriptionTeam() {
  return (
    <div className="about-body pl--150 pr--150 pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--5 pr_sm--5">
      <div className="pb--30 pt_sm--100">
        {aboutTeamData.map((x, index) => {
          return (
            <Container key={x.id}>
              <div
                className={
                  index % 2 === 1
                    ? "about-section-team about-team-r"
                    : "about-section-team"
                }
              >
                {index % 2 === 1 ? (
                  <Fade bottom>
                    <p className="about--t-justified p-theme" id="about--t">
                      {x.desc}
                    </p>
                  </Fade>
                ) : (
                  ""
                )}
                <div
                  className={
                    index % 2 === 1
                      ? "about-team--left--r"
                      : "about-team--left--l"
                  }
                >
                  <Fade bottom>
                    <img
                      className="about-team"
                      src={x.img}
                      alt={`team member - helios ${x.name}`}
                    />
                  </Fade>
                  <Fade bottom>
                    <h3
                      className={
                        index % 2 === 1
                          ? "about--heading h-theme txt-right"
                          : "about--heading h-theme"
                      }
                    >
                      {x.name}
                    </h3>
                  </Fade>
                  <Fade bottom>
                    <p className="a-mini-text p-theme">{x.designation}</p>
                  </Fade>
                  <Fade bottom>
                    <a
                      href={`mailto:${x.email}`}
                      className="a-mini-text a-mini-text-hover p-theme"
                    >
                      <i className="fa fa-envelope" aria-hidden="true"></i>

                      {" " + x.email}
                    </a>
                    {x.location && (
                      <p className="a-mini-text p-theme">
                        <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                        {" " + x.location}
                      </p>
                    )}
                  </Fade>
                </div>
                {index % 2 === 0 ? (
                  <Fade bottom>
                    <p className="about--t-justified p-theme" id="about--t">
                      {x.desc}
                    </p>
                  </Fade>
                ) : (
                  ""
                )}
              </div>
            </Container>
          );
        })}
      </div>
    </div>
  );
}

export default AboutDescriptionTeam;
